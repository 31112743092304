import {
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
} from "@remix-run/react";
import Header from "~/components/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/lib/rdt/radixProvider";

import "../styles/app.css";
// import { appsignal } from "./lib/appsignal";

export function links() {
  return [
    // { rel: 'stylesheet', href: styles },
    {
      rel: "shortcut icon",
      type: "image/svg+xml",
      href: "https://hug.meme/hug.png",
    },
    {
      rel: "mask-icon",
      sizes: "any",
      href: "https://hug.meme/hug.png",
      color: "#5D0FC0",
    },
  ];
}

export const meta: MetaFunction = () => {
  return [{ title: "HUG Meme" }];
};

export default function AppLayout() {
  return (
    <html
      lang="en"
      className="bg-scroll bg-cover bg-no-repeat bg-sun"
      style={{
        backgroundPositionX: "50%",
      }}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="theme-color"
          content="#f97316"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#c2410c"
          media="(prefers-color-scheme: dark)"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Bangers&display=swap"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        ></link>
        <Meta />
        <Links />
      </head>
      <body>
        <RadixProvider>
          <Header />
          <main className="mt-5 md:mt-12 my-2">
            <Outlet />
            <TransactionNotification />
            <ScrollRestoration />
          </main>
        </RadixProvider>
        <Scripts />
      </body>
    </html>
  );
}
